import { BorderSpinner, FormTextbox } from '@allsynx/components';
import { FormEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HubLoginResponse } from 'types/hub-login-response';
import { OidcLoginRequest } from 'types/oidc-login-request';
import { postAnonymous } from 'utils/api-util';
import { urls } from 'utils/environment-constants';

function DemographicsLoginForm(
			props: {
				webDirectory: string,
				isLoading: boolean,
				setIsLoading: (l: boolean) => void,
				needsFullSsn: boolean,
				setNeedsFullSsn: (n: boolean) => void,
				alternateSubmit?: (lastName: string, ssn: string, dob: string) => Promise<{ status: number, ok: boolean, resObj: HubLoginResponse } | null>,
				nextPage?: string,
				overrideAuthType?: string,
				headerMessage?: string
			}) {
	const CODE_NOT_ALLOWED_CHARS_REGEX = /[^0-9]/g;
	const [lastName, setLastName] = useState("");
	const [dobMonth, setDobMonth] = useState("");
	const [dobDay, setDobDay] = useState("");
	const [dobYear, setDobYear] = useState("");
	const [ssn, setSsn] = useState("");
	const [isLastNameTouched, setIsLastNameTouched] = useState(false);
	const [isDobMonthTouched, setIsDobMonthTouched] = useState(false);
	const [isDobDayTouched, setIsDobDayTouched] = useState(false);
	const [isDobYearTouched, setIsDobYearTouched] = useState(false);
	const [isSsnTouched, setIsSsnTouched] = useState(false);
	const [isLastNameValid, setIsLastNameValid] = useState(true);
	const [isDobValid, setIsDobValid] = useState(true);
	const [isSsnValid, setIsSsnValid] = useState(true);
	const [lastNameError, setLastNameError] = useState("");
	const [dobError, setDobError] = useState("");
	const [ssnError, setSsnError] = useState("");
	const [loginError, setLoginError] = useState("");
	const [isShowPassword, setIsShowPassword] = useState(false);

	const navigate = useNavigate();

	const validate = (ignoreTouched: boolean, dd: string|null, mm: string|null, yyyy: string|null, ssNumber: string|null): boolean => {
		var dayToValidate = ignoreTouched || dd == null ? dobDay : dd;
		var monthToValidate = ignoreTouched || mm == null ? dobMonth : mm;
		var yearToValidate = ignoreTouched || yyyy == null ? dobYear : yyyy;
		var ssnToValidate = ignoreTouched || ssNumber == null ? ssn : ssNumber;

		var isValid = true;
		if (lastName === "" && (isLastNameTouched || ignoreTouched)) {
			setLastNameError("The Last Name field is required");
			setIsLastNameValid(false);
			isValid = false;
		} else {
			setIsLastNameValid(true);
			setLastNameError("");
		}

		if ((monthToValidate === "" && (isDobMonthTouched || ignoreTouched)) || (dayToValidate === "" && (isDobDayTouched || ignoreTouched)) || (yearToValidate === "" && (isDobYearTouched || ignoreTouched))) {
			setDobError("The Date of Birth fields are required");
			setIsDobValid(false);
			isValid = false;
		} else if ((ignoreTouched || (isDobMonthTouched && isDobDayTouched && isDobYearTouched)) && monthToValidate && dayToValidate && yearToValidate) {
			var month = !Number.isNaN(parseInt(monthToValidate)) ? parseInt(monthToValidate) : -1;
			var day = !Number.isNaN(parseInt(dayToValidate)) ? parseInt(dayToValidate) : -1;
			var year = !Number.isNaN(parseInt(yearToValidate)) ? parseInt(yearToValidate) : -1;

			if (month < 1 || month > 12 || day < 1 || day > 31 || year < 1900 || year > (new Date().getFullYear())) {
				setDobError("Please provide a valid Date of Birth");
				setIsDobValid(false);
				isValid = false;
			} else if ((new Date(year, month-1, day)).getMonth() !== (month - 1)) {
				// this happens if they enter an invalid number of days for that particular month
				// ie - 02/30/yyyy will get converted to a march date
				setDobError("Please provide a valid Date of Birth");
				setIsDobValid(false);
				isValid = false;
			} else {
				setIsDobValid(true);
				setDobError("");
			}
		} else	{
			setIsDobValid(true);
			setDobError("");
		}

		if (ssnToValidate === "" && (isSsnTouched || ignoreTouched)) {
			setSsnError("The SSN field is required");
			setIsSsnValid(false);
			isValid = false;
		} else if (	
				   (Number.isNaN(Number(ssnToValidate))
				|| (ssnToValidate.length < 4 && !props.needsFullSsn) 
				|| (ssnToValidate.length < 9 && props.needsFullSsn)) 
				&& (isSsnTouched || ignoreTouched)
				) {
			setSsnError("Please provide a valid SSN");
			setIsSsnValid(false);
			isValid = false;
		} else {
			setIsSsnValid(true);
			setSsnError("");
		}
		
		return isValid;
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();

		setIsLastNameTouched(true);
		setIsDobMonthTouched(true);
		setIsDobDayTouched(true);
		setIsDobYearTouched(true);
		setIsSsnTouched(true);

		if (validate(true, null, null, null, null)) {
			setLoginError("");
			props.setIsLoading(true);

			try {
				const month = !Number.isNaN(parseInt(dobMonth)) ? parseInt(dobMonth) : -1;
				const day = !Number.isNaN(parseInt(dobDay)) ? parseInt(dobDay) : -1;
				const year = !Number.isNaN(parseInt(dobYear)) ? parseInt(dobYear) : -1;
				const fullDob = new Date(year, month-1, day);
				const fullDobIsoString = fullDob.toISOString().substring(0, 10);

				var response: { status: number, ok: boolean, resObj: HubLoginResponse } | null = null;


				if (!props?.alternateSubmit) {
					var urlencoded = new URLSearchParams();
					urlencoded.append("lastName", lastName);
					urlencoded.append("ssn", ssn);
					urlencoded.append("dateOfBirth", fullDobIsoString);
					urlencoded.append("webDirectory", props.webDirectory);

					response = await postAnonymous<HubLoginResponse>("/api/login/employees/basic", urlencoded);
				} else {
					response = await props.alternateSubmit(lastName, ssn, fullDobIsoString);
				}

				if (response?.ok && response?.resObj?.success && !response?.resObj?.needsMfa && (!response.resObj.errors || response.resObj.errors.length < 1)) {
					var authType = encodeURIComponent("Basic Authentication");
					if (props.overrideAuthType) {
						authType = props.overrideAuthType;
					}
					var newLocation = `${urls.loginRedirect}?LoginToken=${response.resObj.token}&AuthType=${authType}`;
					if (props.nextPage) {
						newLocation += `&NextPage=${props.nextPage}`;
					}
					window.location.href = newLocation;
				} else if (response?.resObj?.needsMfa) {
					var authType = encodeURIComponent("Basic Authentication");
					if (props.overrideAuthType) {
						authType = props.overrideAuthType;
					}
					var navLocation = `/mfa/options?AuthType=${authType}`;
					if (props.nextPage) {
						navLocation += `&NextPage=${props.nextPage}`;
					}
					navigate(navLocation, { replace: false });
				} else if (response?.resObj?.additionalInfoNeeded) {
					props.setNeedsFullSsn(true);
					setIsShowPassword(false);
					setSsn('');
					setSsnError('');
					setIsSsnTouched(false);
					props.setIsLoading(false);
				}

				if (!response?.resObj?.additionalInfoNeeded && response?.resObj?.errors && response.resObj.errors.length > 0) {
					setLoginError(response.resObj.errors);
					props.setIsLoading(false);
					props.setNeedsFullSsn(false);
					setSsn('');
				}
				
			} catch (error) {
				console.error(error);
				setLoginError("Error logging in. Please try again.");
				props.setIsLoading(false);
			}
		}

	};

	useEffect(()=>{
		document.getElementById("tbLastName")?.focus();
	}, []);

	const handleMonthChange = (value: string) => {
		value = value.replaceAll(CODE_NOT_ALLOWED_CHARS_REGEX, "");
		if (value.length > 2) {
			value = value.substring(0, 2);
		}

		setDobMonth(value);
		if (isDobMonthTouched || value.length > 0) {
			setIsDobMonthTouched(true);
		}
		validate(false, null, value, null, null);
	};

	const handleDayChange = (value: string) => {
		value = value.replaceAll(CODE_NOT_ALLOWED_CHARS_REGEX, "");
		if (value.length > 2) {
			value = value.substring(0, 2);
		}

		setDobDay(value);
		if (isDobDayTouched || value.length > 0) {
			setIsDobDayTouched(true);
		}
		validate(false, value, null, null, null);
	};

	const handleYearChange = (value: string) => {
		value = value.replaceAll(CODE_NOT_ALLOWED_CHARS_REGEX, "");
		if (value.length > 4) {
			value = value.substring(0, 4);
		}

		setDobYear(value);
		if (isDobYearTouched || value.length > 0) {
			setIsDobYearTouched(true);
		}
		// we need to hide the prompt if the user is still in the process of typing the 4 digit year
		// however, the prompt should still appear if sign in is clicked on prematurely 
		if(isDobYearTouched && value.length > 3) {
			validate(false, null, null, value, null);
		}
	};

	const handleSsnChange = (value: string) => {		
		if (value.length > 4 && !props.needsFullSsn) {
			value = value.substring(0, 4);
		} else if (value.length > 9 && props.needsFullSsn) {
			value = value.substring(0, 9);
		}

		setSsn(value);
		if (isSsnTouched || value.length > 0) {
			setIsSsnTouched(true);
		}
		// we need to hide the prompt if the user is still in the process of typing the SSN
		// however, the prompt should still appear if sign in is clicked on prematurely 
		if(isSsnTouched && (
				(value.length > 3 && !props.needsFullSsn) ||
				(value.length > 8 && props.needsFullSsn)
			)
	    ) {
			validate(false, null, null, null, value);
		}
	};

	return(
		<>
		{
			!props.needsFullSsn ?
				<Form id="divLoginForm" className="divForm" onSubmit={handleSubmit} noValidate>
					<div id="divEmployeeFormFields">						
						{
						(props.headerMessage) ? 
							<div><p>{props.headerMessage}</p></div>
						:
							null
						}
						<FormTextbox
							type={"text"}
							placeholder={"Last Name"}
							id="tbLastName"
							name={"lastName"}
							onChange={(e) => {setLastName(e.target.value);setIsLastNameTouched(true);validate(false, null, null, null, null);}}
							value={lastName}
							label="Last Name"
							isLabelHidden={true}
							isInvalid={!isLastNameValid}
							errorMessage={lastNameError}
							onBlur={() => validate(false, null, null, null, null)}
							touched={isLastNameTouched}
							disabled={props.isLoading}
							fieldWidth="full" />
						<div className='dob-fields'>
							<FormTextbox
								type={"text"}
								placeholder={"Birth MM"}
								id={"tbDateOfBirthMonth"}
								name="dateOfBirthMonth"
								onChange={(e) => { handleMonthChange(e.target.value); } }
								value={dobMonth}
								label="Birth MM"
								isLabelHidden={true}
								isInvalid={!isDobValid}
								errorMessage={""}
								onBlur={() => validate(false, null, null, null, null)}
								touched={isDobMonthTouched}
								disabled={props.isLoading}
								fieldWidth="full" />
							<FormTextbox
								type={"text"}
								placeholder={"Birth DD"}
								id={"tbDateOfBirthDay"}
								name="dateOfBirthDay"
								onChange={(e) => { handleDayChange(e.target.value); } }
								value={dobDay}
								label="Birth DD"
								isLabelHidden={true}
								isInvalid={!isDobValid}
								errorMessage={""}
								onBlur={() => validate(false, null, null, null, null)}
								touched={isDobDayTouched}
								disabled={props.isLoading}
								fieldWidth="full" />
							<FormTextbox
								type={"text"}
								placeholder={"Birth YYYY"}
								id={"tbDateOfBirthYear"}
								name="dateOfBirthYear"
								onChange={(e) => { handleYearChange(e.target.value); } }
								value={dobYear}
								label="Birth YYYY"
								isLabelHidden={true}
								isInvalid={!isDobValid}
								errorMessage={""}
								onBlur={() => validate(false, null, null, null, null)}
								touched={isDobYearTouched}
								disabled={props.isLoading}
								fieldWidth="full" />
							{dobError ? <div style={{"display": "block"}} className="invalid-feedback">{dobError}</div> : null}
						</div>
						<div className="PasswordWrapper">
							<FormTextbox
								type={isShowPassword ? "text" : "password"}
								placeholder={"Last Four Digits of SSN"}
								id={"tbSsn"}
								name="ssn"
								onChange={(e) => { handleSsnChange(e.target.value); } }
								value={ssn}
								maxLength={4}
								label="Last Four Digits of SSN"
								isLabelHidden={true}
								isInvalid={!isSsnValid}
								errorMessage={ssnError}
								onBlur={() => validate(false, null, null, null, null)}
								touched={isSsnTouched}
								disabled={props.isLoading}
								fieldWidth="full" 
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										e.currentTarget.form?.requestSubmit();
									}
								}}
								/>
							<div className= {isShowPassword ? 'Visibility-On' : 'Visibility-Off' } onClick={() => setIsShowPassword(!isShowPassword)}></div>
						</div>
						<div id='divLoginButton'>
							<Button id='btnLogin' className='auth-button' variant='outline-primary' type='submit' disabled={props.isLoading}>{(props.isLoading) ? <BorderSpinner isFooterSavingSpinner/> : "Login"}</Button>
						</div> 
					</div>
					{loginError.length > 0 ? <div id="divLoginErrorMessage" className="Errors">{loginError}</div> : null}
				</Form>
			:
				<Form id="divLoginFormSsn" className="divForm" onSubmit={handleSubmit} noValidate>
					<p>For security purposes, additional information is required to access your account.  Please provide your full social security number.</p>
					<div id="divEmployeeFullSsn">
						<div className="PasswordWrapper">
							<FormTextbox
								type={isShowPassword ? "text" : "password"}
								placeholder={"Social Security Number"}
								id={"tbFullSsn"}
								name="fullssn"
								onChange={(e) => { handleSsnChange(e.target.value); } }
								value={ssn}
								maxLength={9}
								label="Social Security Number"
								isLabelHidden={true}
								isInvalid={!isSsnValid}
								errorMessage={ssnError}
								onBlur={() => validate(false, null, null, null, null)}
								touched={isSsnTouched}
								disabled={props.isLoading} 
								fieldWidth="full"
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										e.currentTarget.form?.requestSubmit();
									}
								}}
								/>
							<div className= {isShowPassword ? 'Visibility-On' : 'Visibility-Off' } onClick={() => setIsShowPassword(!isShowPassword)}></div>
						</div>
						<div id='divLoginButtonSsn'>
							<Button id='btnLoginSsn' className='auth-button' variant='outline-primary' type='submit' disabled={props.isLoading}>{(props.isLoading) ? <BorderSpinner isFooterSavingSpinner/> : "Verify"}</Button>
						</div> 
					</div>
				</Form>
			}

		</>
	);
}

export default DemographicsLoginForm;